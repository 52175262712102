import React from 'react';
import { Link } from 'gatsby';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';

import { useUser } from '@util/providers/AuthProvider';
import { usePlan } from '@util/providers/Plan';

import RateInfo from '@components/PlanCard/RateInfo';
import { PlanCard } from '@ui/PlanCard';
import SubscribeButton from './SubscribeButton';
import PlanTermSelect from './PlanTermSelect';

export default function({ plan, hideLearnMoreLink, className }) {
  const { t } = useTranslation();
  const { bmoUser } = useUser();
  const { termIsYearly, trialLength } = usePlan();

  const community = plan.id === '0';
  const organization = plan.id === '3';
  const personalOrProfessional = !community && !organization;

  const canTrial =
    bmoUser && bmoUser.subscriptionInfo
      ? bmoUser.subscriptionInfo.isTrialEligible
      : true;

  //* gets static rate
  //* || gets trial rate
  //* || gets plan rate based on monthly/yearly state
  function getRatePlanByType(p, isTrial) {
    if (p.staticRatePlan) return p.staticRatePlan;
    if (isTrial) return p.ratePlans.find(rp => rp.isTrial);
    return p.ratePlans.find(rp => (termIsYearly ? rp.isYearly : rp.isMonthly));
  }

  const currentRate = getRatePlanByType(plan);

  return (
    <>
      <PlanCard className={className}>
        <PlanCard.Header className="text-dark">{plan.name}</PlanCard.Header>
        <PlanCard.Body>
          <p className="description d-none d-lg-block">
            {plan.shortDescription}
          </p>
          <RateInfo rate={currentRate} plan={plan} />
          {hideLearnMoreLink ? (
            personalOrProfessional && <PlanTermSelect />
          ) : (
            <div className="learn-more-link">
              <Link
                id={'LearnMore' + plan.key}
                data-testid={'LearnMore' + plan.key}
                to={`/${plan.key}`}
              >
                {t('action.learnMore')}&nbsp;&rsaquo;
              </Link>
            </div>
          )}
        </PlanCard.Body>
        <PlanCard.Footer className="border-0">
          {plan.hasTrial && canTrial && (
            <div className="trial-link">
              <p>
                {t('action.try')}
                <LightBold> {t('action.free')} </LightBold>
                {/* prettier-ignore */}
                <Trans i18nKey="action.forTrialDays">
                  for
                  {{
                    trialLength: trialLength,
                  }}
                  days
                </Trans>
              </p>
            </div>
          )}
          <SubscribeButton plan={plan} />
          <div className="plan-card-disclaimer mt-2">
            {t(currentRate.disclaimerKey)}
          </div>
        </PlanCard.Footer>
      </PlanCard>
    </>
  );
}

const LightBold = styled.span`
  font-weight: 600;
`;
