import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { navigate, Link } from 'gatsby';

import { PlanList, CardContainer } from '@ui/Plans';
import Toggler from '@components/Miscellaneous/Toggler';
import PlanCard from '@components/PlanCard';
import { useYearlySavings } from '@util/Plans/PlanFunctions';

export default function Plans({ plans }) {
  const [showPlanComparison, setShowPlanComparison] = useState(false);
  const [openPlanFeatures, setOpenPlanFeatures] = useState();
  const percentSavings = useYearlySavings();
  const { t } = useTranslation();

   useEffect(() => {
    //* close open feature on resize

    function handleResize() {
      setOpenPlanFeatures();
      setShowPlanComparison(false);
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function renderCardContainer(plans, x) {
    return (
      <CardContainer key={x}>
        <PlanCard
          plan={plans[x]}
          displayComparisonChart={showPlanComparison}
          className="mb-0"
        />
      </CardContainer>
    );
  }

  return (
    <>
      {/* mobile toggle */}
      <div
        className="position-fixed d-sm-none border-top border-gray"
        style={{
          zIndex: 1000,
          right: 0,
          left: 0,
          bottom: 0,
          backgroundColor: '#fafafa',
        }}
      >
        <Toggler justify="center" />
        <div className="text-center pb-4 text-muted">
          {/* prettier-ignore */}
          <Trans i18nKey="save17Percent">
            <strong>
              Save
              {{
                percent: percentSavings,
              }}
              %
            </strong>
            with a yearly plan
          </Trans>
        </div>
      </div>

      {/* non-mobile toggle */}
      <div className="d-none d-sm-block">
        <Toggler justify="center" />
        <h5 className="text-center pb-4">
          {/* prettier-ignore */}
          <Trans i18nKey="save17Percent">
            <strong>
              Save
              {{
                percent: percentSavings,
              }}
              %
            </strong>
            with a yearly plan
          </Trans>
        </h5>
      </div>

      {
        //* mobile plan list
        <div className="d-block d-sm-none mt-3">
          {plans &&
            plans.map((p, i) => {
              const cols = 1;
              if (i % cols === 0) {
                return (
                  <div key={i} className="mb-4">
                    <PlanList>
                      {(() => {
                        let cards = [];
                        for (let x = 0; x < cols; x++) {
                          if (plans[i + x]) {
                            cards = [
                              ...cards,
                              renderCardContainer(plans, i + x),
                            ];
                          }
                        }
                        return cards;
                      })()}
                    </PlanList>
                  </div>
                );
              }
              return null;
            })}
        </div>
      }

      {
        //* tablet plan list
      }
      <div className="d-none d-sm-block d-xl-none">
        {plans &&
          plans.map((p, i) => {
            const cols = 2;
            if (i % cols === 0) {
              return (
                <div key={i} className="mb-4">
                  <PlanList>
                    {(() => {
                      let cards = [];
                      for (let x = 0; x < cols; x++) {
                        if (plans[i + x]) {
                          cards = [...cards, renderCardContainer(plans, i + x)];
                        }
                      }

                      return cards;
                    })()}
                  </PlanList>
                </div>
              );
            }
            return null;
          })}
      </div>

      {
        //* desktop plan list
      }
      <PlanList className="d-none d-xl-flex">
        {plans &&
          plans.map((p, i) => (
            <CardContainer key={i}>
              <PlanCard
                plan={p}
                displayComparisonChart={showPlanComparison}
                className="mb-xl-0"
              />
            </CardContainer>
          ))}
      </PlanList>
      <div className="text-center mt-4">
        <div className="learn-more-link">
          <button
                onClick={() => navigate('/compare')}
                className="btn btn-outline-secondary ml-2"
              >
                {t('compare:general.title')}
              </button>
        </div>
        {t('subscriptionLicense.toggle.preferencedText')}&nbsp;
        <Link id={'onetime'}
            data-testid={'onetime'}
            to={`/license`}>
              {t('subscriptionLicense.toggle.tolicenseText')}?
          </Link>
      </div>      
    </>
  );
}
