import React from 'react';
import { useTranslation } from 'react-i18next';
import { UncontrolledDropdown, DropdownItem, DropdownMenu } from 'reactstrap';

import { usePlan } from '@util/providers/Plan';

import { TermDropdownToggle } from '@ui/PlanCard';

export default function PlanTermSelect() {
  const { t } = useTranslation();
  const { termIsYearly, setTermIsYearly } = usePlan();
  return (
    <UncontrolledDropdown>
      <TermDropdownToggle
        data-toggle="dropdown"
        id="TermDropdownToggle"
        data-testid="TermDropdownToggle"
        className="btn-outline-gray"
        caret
      >
        {termIsYearly ? t('yearly') : t('monthly')} {t('subscription')}
      </TermDropdownToggle>
      <DropdownMenu id="TermDropdownMenu" data-testid="TermDropdownMenu">
        <DropdownItem className="pl-3" onClick={() => setTermIsYearly(false)}>
          <div className="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="monthlyTermOption"
              name="styleguideRadioInline"
              className="custom-control-input"
              checked={termIsYearly ? false : true}
              readOnly
            />
            <label className="custom-control-label" htmlFor="monthlyTermOption">
              {t('monthly')} {t('subscription')}
            </label>
          </div>
        </DropdownItem>
        <DropdownItem className="pl-3" onClick={() => setTermIsYearly(true)}>
          <div className="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="yearlyTermOption"
              name="styleguideRadioInline"
              className="custom-control-input"
              checked={termIsYearly ? true : false}
              readOnly
            />
            <label className="custom-control-label" htmlFor="yearlyTermOption">
              {t('yearly')} {t('subscription')}
            </label>
          </div>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}
