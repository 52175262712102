import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { usePlan } from '@util/providers/Plan';

import { useUser } from '@util/providers/AuthProvider';
import { useResetSession } from '@util/providers';

import PageHeader from '@ui/PageHeader';
import SEO from '../components/seo';

import PlansModule from '../modules/Plans';

import Go from '@util/CustomRedirect';

const IndexPage = function() {
  const { t } = useTranslation('page');
  const { bmoUser } = useUser();
  const { plans } = usePlan();
  const resetSession = useResetSession();

  const isSubscriber =
    bmoUser &&
    bmoUser.zuoraInfo &&
    bmoUser.zuoraInfo.subscriptions &&
    bmoUser.zuoraInfo.subscriptions.length !== 0;
  useEffect(() => {
    //* reset
    resetSession();
    // eslint-disable-next-line
  }, []);

  return (
    <Go to="/myplan" when={isSubscriber}>
      <SEO title={t('subscription')} />
      <div className="row">
        <div className="col">
          <PageHeader>{t('subscription')}</PageHeader>
        </div>
      </div>

      <PlansModule plans={plans} />
    </Go>
  );
};

export default IndexPage;
